.clr-cloudy-blue {
    color: var(--cloudy-blue);
}

.clr-primary {
    color: var(--primary-color);
}

.clr-blue-grey {
    color: var(--blue-grey);
}

.clr-white {
    color: var(--white);
}

.clr-dark {
    color: var(--dark);
}

.clr-success {
    color: var(--success);
}

.clr-dusk {
    color: var(--dusk);
}

.clr-coral {
    color: var(--coral);
}

.bg-pale-grey {
    background: var(--pale-grey);
}

.bg-success {
    background: var(--success);
}

.bg-white {
    background: var(--white);
}

.bg-primary {
    background: var(--primary-color);
}

.font-light {
    font-family: light;
}

.font-normal {
    font-family: normal;
}

.font-regular {
    font-family: regular;
}

.font-heavy {
    font-family: heavy;
}

.font-extra-heavy {
    font-family: extra-heavy;
}

.min-height-auto {
    min-height: auto;;
}

.dsp-inb {
    display: inline-block;
}

.dsp-n {
    display: none;
}

.dsp-flex {
    display: flex;
}

.fs-14 {
    font-size: 0.875rem;
}

.fs-13 {
    font-size: 0.815rem;
}

.fs-12 {
    font-size: 0.75rem;
}

.fs-11 {
    font-size: 0.687rem;
}

.fs-10 {
    font-size: 0.635rem;
}

.fs-18 {
    font-size: 1.125rem;
}

.fs-20 {
    font-size: 1.25rem;
}

.fs-30 {
    font-size: 1.875rem;
}

.fs-32 {
    font-size: 2rem;
}

.p-5 {
    padding: 5px;
}

.p-10 {
    padding: 10px;
}

.p-15 {
    padding: 15px;
}

.p-18 {
    padding: 18px;
}

.p-25 {
    padding: 25px;
}

.p-t-15 {
    padding-top: 15px;
}

.mr-16 {
    margin: 16px;
}
.ml-16 {
    margin: 16px;
}

.mr-v-10 {
    margin: 10px 0;
}

.mr-v-15 {
    margin: 15px 0;
}

.m-b-15 {
    margin-bottom: 15px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-15 {
    margin-top: 15px;
}

.m-t-25 {
    margin-top: 25px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-r-30 {
    margin-right: 30px;
}

.p-v10 {
    padding: 10px 0;
}

.isDisabled {
    pointer-events: none;
    opacity: 0.5;
}

.readonly {
    pointer-events: none;
}

.algn-self-start {
    align-self: flex-start;
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-start {
    justify-content: flex-start;
}

.justify-space-between {
    justify-content: space-between;
}

.flex-justify-end {
    justify-content: flex-end;
}

.flex-align-start {
    align-items: flex-start;
}

.h-100 {
    height: 100%;
}

.h-100vh {
    height: 100vh;
}

.w-100 {
    width: 100%;
}

.w-80 {
    width: 80%;
}

.w-40 {
    width: 40%;
}

.flt-lft {
    float: left;
}

.flt-rgt {
    float: right;
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0
}

.clearfix {
    display: inline-block
}

.no-bold {
    font-weight: normal;
}

.underline {
    text-decoration: underline;
}

.algn-cntr {
    text-align: center;
}

.algn-lft {
    text-align: left;
}

.pos-abs {
    position: absolute;
}

.pos-rel {
    position: relative;
}

.pos-fix {
    position: fixed;
}