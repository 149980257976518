.login-container {
    .login-header-logo {
        height: 30px;
    }

    .login-header {
        .headr-inner-main {
            padding: 15px;
        }
    }

    .login-banner {
        max-height: 200px;
        min-height: 150px;
    }
}