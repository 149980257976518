@font-face {
  font-family: light;
  src: url(../assets/font/light.ttf);
}

@font-face {
  font-family: normal;
  src: url(../assets/font/normal.ttf);
}

@font-face {
  font-family: regular;
  src: url(../assets/font/regular.ttf);
}

@font-face {
  font-family: heavy;
  src: url(../assets/font/heavy.ttf);
}
@font-face {
  font-family: 'extra-heavy';
  src: url(../assets/font/extra-heavy.ttf);
}
iframe{
  border:none;
}
body {
  margin: 0;
  font-family: normal, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  font-size: 16px;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height:100vh;
}

* {
  box-sizing: border-box;
}

[disabled] {
  opacity: 0.6;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--primary-color);
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  font-size: inherit;
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  html, body {
    font-size: 18px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  html, body {
    font-size: 18px;
  }
}

/* Extra small devices (phones, 320px and down) IPhone 5/SE */
@media only screen and (max-width: 320px) {
  html, body {
    font-size: 12px;
  }
}