:root {
	--primary-color: #00afcb;
	--pale-grey: #f8f7fa;
	--pale-grey-two: #eeecf9;
	--pale-grey-four: #e5e3ef;
	--blue-grey: #8d8b97;
	--dusk:#585371;
	--dark:#211b3e;
	--cloudy-blue:#c0becd;
	--white:#ffffff;
	--light-mustard: #ffca59;
	--success: #02a758;
	--secondary-color: #007b78;
	--error: #e96062;
	--ice-blue: #f2fdff;
	--coral: #ed6a5b;
	--font-medium: regular;
	--font-medium: normal;
	--font-light: light;
	--font-heavy: heavy;
	--font-extra-heavy: extra-heavy;
	/* --normal-font: normal-font;
	--light-font: light-font;
	--regular-font: regular-font;
	--bold-font: bold-font;
	--extra-bold-font: extra-bold-font;
	--medium-font: medium-font;
	--font-default: Avenir;
	--font-medium: Avenir-Medium;
	--font-light: Avenir-Light;
	--font-bold: Avenir Next;
	--ping-fang-medium: ping-fang-medium; */
  }

  *{
	box-sizing: border-box;
  }