.loader__container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  z-index: 99999;
}
.loader__container.is-visible {
  opacity: 1;
  visibility: visible;
}


.loader__background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 25px 15px;
  z-index: 25;
  min-width: 130px;
}

.loader__element {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.loader__text {
  color: var(--dusk);
  font-family: var(--font-default);
  font-size: 0.875rem;
  margin-top: 10px;
  margin-bottom: 2.5px;
  text-align: center;
  position: relative;
  z-index: 25;
}

.loader__overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--white);
  z-index: 1;
}