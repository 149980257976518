.material {
  position: relative;
  padding: 1px;
  border: 1px solid var(--pale-grey-four);
  border-radius: 4px;
  box-shadow: none;
  height: 48px;
  overflow: visible;
  width: 100%;
  

  .down-arrow-img {
    height: 24px;
    margin-right: 12px;
  }
  .plan-selection {
    width: 30%;
    font-family: "Avenir-Heavy";
    font-size: 12px;
    color: #3b2a98;
    white-space: nowrap;
    margin-right: 10px;
  }
  input {
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    padding: 12px 10px;
    border-radius: 4px;
    border: none;
    font-size: 1rem;
    outline: none;
    cursor: text;
    font-weight: 900;
    line-height: 1.71;
    font-family: "Avenir-Heavy";
    &::-webkit-input-placeholder {
      transition: color 300ms ease;
    }
    &:not(:focus)::-webkit-input-placeholder {
      color: #3b2a98;
    }
  }
  input:focus ~ hr {
    transform: scaleX(1);
    visibility: visible;
  }

  label {
    white-space: nowrap;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 7px;
    font-size: 1rem;
    height: 22px;
    color: var(--dusk);
    transform-origin: 0 -220%;
    transition: transform 300ms ease;
    pointer-events: none;
    background-color: #fff;
    padding: 0 10px;
    white-space: nowrap;

    .required-text {
      color: var(--error);
    }
  }

  .active{
    transform: scale(0.6);
  }
  input:focus ~ label,
  input:valid ~ label,
  input[readonly] ~ label {
    transform: scale(0.6);
  }
}
